import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  User,
  UserCredential,
  sendPasswordResetEmail,
} from "firebase/auth";
// import { firebaseApp } from "../../services/firebase/initFirebase";
import { FirebaseError } from "@firebase/util";
import { queryClient, QueryKeys } from "../react-query/query-client";
import { useQuery } from "react-query";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import * as firebase from "firebase/app";
import { getDatabase } from "firebase/database";
import { firebaseConfig } from "../../constants/env";

export interface LoggedUser {
  user: User | null;
  customerId: string;
}

export function useFirebaseAuth() {
  const router = useRouter();

  if (!firebase.getApps().length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.getApp(); // if already initialized, use that one
  }
  const auth = getAuth(firebase.getApp());

  const { t } = useTranslation("common");
  async function logInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential | string> {
    try {
      return await signInWithEmailAndPassword(auth, email, password);
    } catch (err: unknown) {
      const error = err as FirebaseError;
      return error.message;
    }
  }

  async function logOutWithEmailAndPassword(): Promise<void> {
    try {
      await signOut(auth);
      await queryClient.invalidateQueries(QueryKeys.USER);
      await router.push("/");
    } catch (err: unknown) {}
  }

  function returnError(error: string) {
    if (
      error.includes("auth/user-not-found") ||
      error.includes("auth/wrong-password")
    ) {
      return t("login.invalidCredentials");
    } else if (error.includes("auth/too-many-requests")) {
      return t("login.tooManyRequests");
    } else {
      return t("login.error");
    }
  }

  function getUser(): Promise<LoggedUser | null> {
    return new Promise((resolve, reject) => {
      auth.onAuthStateChanged(
        async (user) => {
          const tokenResult = await user?.getIdTokenResult();
          if (
            tokenResult &&
            tokenResult.claims["user"] &&
            tokenResult.claims["customerId"]
          ) {
            const customerId = tokenResult.claims["customerId"];
            const loggedUser: LoggedUser = {
              user: user,
              customerId: customerId,
            };
            resolve(loggedUser);
          } else {
            resolve(null);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async function sendPasswordReset(email: string): Promise<string> {
    return new Promise((resolve, reject) => {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          resolve("SUCCESS");
        })
        .catch((error) => {
          if (error.code === "auth/user-not-found") {
            resolve("NOT_FOUND");
          } else {
            resolve("ERROR");
          }
          // ..
        });
    });
  }

  const {
    data: user,
    isLoading: userIsLoading,
    isError,
  } = useQuery([QueryKeys.USER, auth], () => getUser(), {
    // enabled: jwt.length>20,
    retry: 3,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  return {
    logInWithEmailAndPassword,
    returnError,
    user,
    userIsLoading,
    isError,
    getUser,
    auth,
    logOutWithEmailAndPassword,
    sendPasswordReset,
  };
}
