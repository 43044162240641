import { ReactNode } from "react";
import { useWindowSize } from "../../../hooks/misc/useWindowSize";
import { mobile } from "../../../constants/env";

type InputProps = {
  children: ReactNode;
  noHeight?: boolean;
};

export function Container(props: InputProps) {
  const { windowSize } = useWindowSize();
  const { children, noHeight } = props;

  return (
    <>
      {windowSize.width && (
        <div
          className={windowSize.width > 1367 ? "container p-4" : "p-4"}
          style={{
            minHeight: !mobile && !noHeight ? "calc(100vh - 75px)" : undefined,
          }}
        >
          {children}
        </div>
      )}
    </>
  );
}
