import { LinkContainer } from "./LinkContainer";
import { Fragment } from "react";
import { Accordion, Menu, Text } from "@mantine/core";
import { useColors } from "../../../../hooks/colors/useColors";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { MenuLink } from "./useLinks";

type InputProps = {
  categoryMap: MenuLink[];
  translate?: boolean;
  closeMenuDrawer?: () => void;
  doctors?: boolean;
  href: string;
};

export function CategoriesMenu({
  categoryMap,
  translate,
  closeMenuDrawer,
  doctors,
  href,
}: InputProps) {
  const { colors } = useColors();
  const { t } = useTranslation("common");

  async function goToLink() {
    if (closeMenuDrawer) {
      closeMenuDrawer();
    }
  }

  function sortMap(): MenuLink[] {
    if (doctors) {
      return categoryMap;
    } else {
      return categoryMap;
    }
  }

  function displayCategories() {
    return (
      <Fragment>
        {categoryMap && (
          <Fragment>
            {sortMap().map((category: MenuLink, index: number) => {
              const links: Record<string, string> = category.links;
              return (
                <Accordion
                  className="mb-0"
                  key={index}
                  styles={{
                    chevron: {
                      // color: "white",
                    },
                  }}
                >
                  <Accordion.Item value="flexibility">
                    <Accordion.Control className="hover:bg-amber-600 rounded-lg my-1">
                      <Text className="!font-bold !text-slate-900 dark:!text-slate-300">
                        {category.title.includes(".")
                          ? category.title.split(".")[1]
                          : category.title}
                      </Text>
                    </Accordion.Control>
                    <Accordion.Panel>
                      {Object.keys(links).map((name, index) => {
                        const link = links[name];
                        return (
                          <Link href={link} key={index}>
                            <Menu.Item
                              key={index}
                              onClick={goToLink}
                              className="!hover:bg-amber-600 rounded-lg mb-1"
                            >
                              <Text
                                key={index}
                                className="text-[1rem]"
                                color={colors.dark}
                              >
                                {name}
                              </Text>
                            </Menu.Item>
                          </Link>
                        );
                      })}
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              );
            })}
          </Fragment>
        )}
      </Fragment>
    );
  }

  return (
    <LinkContainer
      title={t(doctors ? "team.who" : "specialties.what")}
      dropDown={displayCategories()}
      href={href}
    />
  );
}
