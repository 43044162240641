import { getCookie } from "cookies-next";
import { useDarkModeStore } from "../../components/config/store/useDarkModeStore";

export const useColors = () => {
  const { darkMode, setDarkMode } = useDarkModeStore((state) => state);

  const isDarkMode: boolean = darkMode;
  const colors = defaultColors(isDarkMode);

  function getDarkModeCookieValue(): boolean {
    const cookieValue = getCookie("colorScheme");
    if (cookieValue) {
      if (cookieValue.toString() === "dark") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  return {
    colors,
    isDarkMode,
    setDarkMode,
    getDarkModeCookieValue,
  };
};

function defaultColors(dark: boolean) {
  return {
    primary: "#585191",
    primaryContrast: "#1D1655",
    primaryTransparent: "rgba(255, 84, 6, 0.1)",
    lightBlue: "#a5d8ff",
    secondary: dark ? "#A74C07" : "#EF8B3D",
    secondaryContrast: "#FFA763",
    tertiary: dark ? "#c5f6fa" : "#3bc9db",
    dark: dark ? "#e5e7eb" : "#182931",
    white: dark ? "#1A1B1E" : "#fffffF",
    backgroundInput: dark ? "#222428" : "#f8f9fa",
    background: dark ? "#222428" : "#DEDCE9",
    gray: dark ? "#f2f4f8" : "#808080",
    activeDrawer: dark ? "#f2f4f8" : "#3880ff",
    segment: "#808080",
    danger: dark ? "#ff6363" : "#f03e3e",
    success: "#087f5b",
    warning: "#FF9966",
    yellow: "#fcc419",
    border: dark ? "#808080" : "#ddd",
    itemBackground: dark ? "#343a40" : "#e7f5ff",
    // itemBackground: dark ? '#222428' : '#FFF1EB',
    itemTitle: "#ff5406",
    greenSlot: dark ? "#343a40" : "#d3f9d8",
    redSlot: dark ? "#343a40" : "#ffe3e3",
    secondaryBackground: dark ? "#343a40" : "#FCE8D8",
    violetBackground: dark ? "#343a40" : "#FCF0FC",
    violetText: dark ? "#FCF0FC" : "#420344",
    yellowBackground: dark ? "#343a40" : "#FFFCC1",
    yellowText: dark ? "#FFFCC1" : "#615E30",
    grayText: dark ? "#f8f9fa" : "#606060",
    lightRed: "#ffc9c9",
    graySlotColor: dark ? "#222428" : "#dee2e6",
    linkHighlight: dark ? "#808080" : "#7E78AD",
    lightGreen: "#69DB7C",
    alwaysDark: "#182931",
    teal: "#38D9A9",
    cyan: "#3BC9DB",
  };
}
