import { Switch, useMantineColorScheme } from "@mantine/core";
import { useColors } from "../../../hooks/colors/useColors";
import { IconMoon, IconSun } from "@tabler/icons";
import { setCookie } from "cookies-next";
import { mobile } from "../../../constants/env";

export function DarkModeSwitch() {
  const { colors, isDarkMode, setDarkMode, getDarkModeCookieValue } =
    useColors();
  const { setColorScheme } = useMantineColorScheme();

  function toggleDarkMode(value: boolean) {
    const newValue = value ? "dark" : "light";
    setColorScheme(newValue);
    setDarkMode(!isDarkMode);
    setCookie("colorScheme", newValue, { maxAge: 60 * 60 * 24 * 30 });
  }

  return (
    <Switch
      className="flex"
      size={mobile ? "xl" : "lg"}
      styles={{
        track: {
          background: colors.border,
        },
      }}
      onChange={(event) => toggleDarkMode(event.target.checked)}
      checked={isDarkMode}
      radius={30}
      color={colors.primary}
      onLabel={<IconSun size={mobile ? 26 : 20} color={colors.yellow} />}
      offLabel={
        <IconMoon size={mobile ? 26 : 20} color={colors.primaryContrast} />
      }
    />
  );
}
