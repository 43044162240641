import { Fragment, ReactNode } from "react";
import { MantineProvider } from "@mantine/core";
import { useColors } from "../../hooks/colors/useColors";

type InputProps = {
  children: ReactNode;
};

// configure global Mantine provider
export function MantineConfig(props: InputProps) {
  const { isDarkMode } = useColors();
  return (
    <Fragment>
      <MantineProvider
        defaultColorScheme={isDarkMode ? "dark" : "light"}
        // withGlobalStyles
        // withNormalizeCSS
        theme={{
          breakpoints: {
            sm: "640px",
            md: "768px",
            lg: "1124px",
            xl: "1368px",
          },
          fontFamily: "Nunito, sans-serif",
          /** Put your mantine theme override here */

          // colorScheme: isDarkMode ? "dark" : "light",
          // primaryColor: colors.primary.toString(),
          colors: {
            // gray:['#f8f9fa']
          },
          fontSizes: { xl: "20px", sm: "16px", xs: "14px" },
          radius: { xs: "10px", md: "10px", lg: "10px", xl: "10px" },
        }}
      >
        {props.children}
      </MantineProvider>
    </Fragment>
  );
}
