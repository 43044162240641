import "../styles/globals.css";
import { AppProps } from "next/app";
import { QueryClientProvider } from "react-query";
import { queryClient } from "../hooks/react-query/query-client";
import { MantineConfig } from "../components/config/MantineConfig";
import { Navbar } from "../components/pages/navbar/Navbar";
import dynamic from "next/dynamic";
import { appWithTranslation } from "next-i18next";
import { useColors } from "../hooks/colors/useColors";
import { Fragment, useEffect } from "react";
import { useRouter } from "next/router";
import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import { isPhone } from "../constants/env";
import { CookieAccept } from "../components/UI/cookie-accept/CookieAccept";
import { ContactButtons } from "../components/pages/homepage/contact-button/ContactButtons";

// const DynamicFooter = dynamic(
//   () =>
//     import("./../components/pages/homepage/footer/Footer").then(
//       (mod) => mod.Footer
//     ),
//   {
//     ssr: false,
//   }
// );
function MyApp({ Component, pageProps }: AppProps) {
  const { isDarkMode, setDarkMode, getDarkModeCookieValue } = useColors();
  const router = useRouter();
  // const { isMobileSite, setMobileSite } = useMobileSite((state) => state);

  // useEffect(() => {
  //   const { mobile, dark } = router.query;
  //
  //   if (mobile) {
  //     setMobileSite(true);
  //   }
  //
  //   if (dark) {
  //     setDarkMode(true);
  //   }
  // }, [router.query]);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [isDarkMode]);

  const DynamicToast = dynamic(
    () =>
      import("./../components/UI/notifications/MyToast").then(
        (mod) => mod.MyToast
      ),
    {
      ssr: true,
    }
  );

  const DynamicAlert = dynamic(
    () =>
      import("./../components//UI/my-alert/MyAlert").then((mod) => mod.MyAlert),
    {
      ssr: true,
    }
  );

  return (
    <Fragment>
      <QueryClientProvider client={queryClient}>
        <MantineConfig>
          <DynamicToast />
          <DynamicAlert />
          <CookieAccept />
          <div
            style={{
              marginTop: 69,
              // minHeight: "calc(90vh - 291px)",
            }}
          >
            <Navbar />
            <Component {...pageProps} />
            <ContactButtons />
          </div>
          {/*{!isPhone && <DynamicFooter />}*/}
        </MantineConfig>
      </QueryClientProvider>
    </Fragment>
  );
}

export default appWithTranslation(MyApp);
