import { create } from "zustand";
interface DarkModeState {
  openLoginMenu: boolean;
  setOpenLoginMenu: (open: boolean) => void;
}

export const useOpenLoginMenuStore = create<DarkModeState>()((set) => ({
  openLoginMenu: false,
  setOpenLoginMenu: (open: boolean) => {
    set(() => ({
      openLoginMenu: open,
    }));
  },
}));
