import {
  SpecialistData,
  useSpecialistList,
} from "../../../../hooks/react-query/useSpecialistList";
import {
  MultiData,
  useMultiList,
} from "../../../../hooks/react-query/useMultiList";
import {
  SpecialtyData,
  useSpecialtyList,
} from "../../../../hooks/react-query/useSpecialtyList";
import { useTranslation } from "next-i18next";

export interface MenuLink {
  title: string;
  links: Record<string, string>;
}

export interface SpecialtyInfo {
  title: string;
  specialties: SpecialtyData[];
}

export interface SpecialistInfo {
  title: string;
  specialists: SpecialistData[];
}

export function useLinks() {
  const { specialists } = useSpecialistList();
  const { multi } = useMultiList();
  const { specialties } = useSpecialtyList();
  const { t } = useTranslation("common");

  function specialtyLinks(): MenuLink[] {
    const specialityGroupLinks: MenuLink[] = [];
    const specialtyCategories: string[] = [];
    if (specialties) {
      Object.keys(specialties).map((slug) => {
        const specialty: SpecialtyData = specialties[slug];
        if (!specialtyCategories.includes(specialty.category))
          specialtyCategories.push(specialty.category);
      });

      specialtyCategories
        .sort(function (a, b) {
          return a.toString().localeCompare(b.toString());
        })
        .forEach((category) => {
          specialityGroupLinks.push({
            title: category,
            links: {},
          });
        });

      specialityGroupLinks.forEach((link) => {
        const specialtyLinks: Record<string, string> = {};
        Object.keys(specialties).map((slug) => {
          const specialty: SpecialtyData = specialties[slug];
          if (specialty.category === link.title) {
            specialtyLinks[specialty.name] = "/especialidades/" + slug;
          }
          link.links = specialtyLinks;
        });
      });
    }

    return specialityGroupLinks;
  }

  function specialtyCards(): SpecialtyInfo[] {
    const specialityGroupLinks: SpecialtyInfo[] = [];
    const specialtyCategories: string[] = [];
    if (specialties) {
      Object.keys(specialties).map((slug) => {
        const specialty: SpecialtyData = specialties[slug];
        if (!specialtyCategories.includes(specialty.category))
          specialtyCategories.push(specialty.category);
      });

      specialtyCategories
        .sort(function (a, b) {
          return a.toString().localeCompare(b.toString());
        })
        .forEach((category) => {
          specialityGroupLinks.push({
            title: category,
            specialties: [],
          });
        });

      specialityGroupLinks.forEach((category) => {
        const categorySpecialties: SpecialtyData[] = [];
        Object.keys(specialties).map((slug) => {
          const specialty: SpecialtyData = specialties[slug];
          if (specialty.category === category.title) {
            categorySpecialties.push(specialty);
          }
        });
        category.specialties = categorySpecialties;
      });
    }

    return specialityGroupLinks;
  }

  function specialistCards(): SpecialistInfo[] {
    const specialistGroupLinks: SpecialistInfo[] = [];
    const specialistCategories: string[] = [];
    if (specialists) {
      Object.keys(specialists).map((slug) => {
        const specialist: SpecialistData = specialists[slug];
        if (specialist.categories) {
          specialist.categories.map((cat) => {
            if (!specialistCategories.includes(cat))
              specialistCategories.push(cat);
          });
        }
      });

      specialistCategories
        .sort(function (a, b) {
          return a.toString().localeCompare(b.toString());
        })
        .forEach((category) => {
          specialistGroupLinks.push({
            title: category,
            specialists: [],
          });
        });

      specialistGroupLinks.forEach((category) => {
        const categorySpecialists: SpecialistData[] = [];
        Object.keys(specialists).map((slug) => {
          const specialist: SpecialistData = specialists[slug];
          if (specialist.categories) {
            specialist.categories.map((cat) => {
              if (cat === category.title) {
                categorySpecialists.push(specialist);
              }
            });
          }
        });
        category.specialists = categorySpecialists;
      });
    }

    return specialistGroupLinks;
  }

  function multiLinks(): Record<string, string> {
    const multiLinks: Record<string, string> = {};
    if (multi) {
      Object.keys(multi).map((slug) => {
        const multiData: MultiData = multi[slug];
        multiLinks[multiData.name] = "/multi/" + slug;
      });
    }

    return multiLinks;
  }

  function specialistLinks(): MenuLink[] {
    const specialistGroupLinks: MenuLink[] = [];
    const specialistCategories: string[] = [];
    if (specialists) {
      Object.keys(specialists).map((slug) => {
        const specialist: SpecialistData = specialists[slug];
        if (specialist.categories) {
          specialist.categories.map((cat) => {
            if (!specialistCategories.includes(cat))
              specialistCategories.push(cat);
          });
        }
      });

      specialistCategories
        .sort(function (a, b) {
          return a.toString().localeCompare(b.toString());
        })
        .forEach((category) => {
          specialistGroupLinks.push({
            title: category,
            links: {},
          });
        });

      specialistGroupLinks.map((link) => {
        const specialtyLinks: Record<string, string> = {};
        Object.keys(specialists).map((slug) => {
          const specialist: SpecialistData = specialists[slug];
          if (specialist.categories) {
            specialist.categories.map((cat) => {
              if (cat === link.title) {
                specialtyLinks[specialist.name] = "/especialistas/" + slug;
              }
            });
          }
          link.links = specialtyLinks;
        });
      });
    }

    return specialistGroupLinks;
  }

  function infoLinks(): Record<string, string> {
    const teamLinks: Record<string, string> = {};
    teamLinks[t("pricing")] = "/precario";
    teamLinks[t("faq")] = "/faq";
    teamLinks[t("partnership.name")] = "/parcerias";
    // teamLinks[t("rgdp")] = "/privacy";
    teamLinks[t("terms")] = "/termos-e-condicoes";
    teamLinks[t("privacy")] = "/privacidade";
    teamLinks[t("contacts")] = "/contactos";

    return teamLinks;
  }

  return {
    specialtyLinks,
    multiLinks,
    specialistLinks,
    infoLinks,
    specialtyCards,
    specialistCards,
  };
}
