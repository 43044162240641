import { ref, onValue, getDatabase } from "firebase/database";
// import { database } from "../../services/firebase/initFirebase";
import { QueryKeys } from "./query-client";
import { useQuery } from "react-query";
import { EntryData } from "../../constants/model/models";
import { useRouter } from "next/router";
import * as firebase from "firebase/app";
import { firebaseConfig } from "../../constants/env";
import { getAuth } from "firebase/auth";

export interface SpecialistData extends EntryData {
  name: string;
  secondaryDescription: string;
  medicalOrderId: string;
  languages: string[];
  category: string;
  type: "doctor" | "psycho" | "neuro";
  specialties: string[];
  multi: string[];
  services: Service[];
  categories: string[];
}

export interface Service {
  description: string;
  price: number;
}

export function useSpecialistList() {
  const router = useRouter();

  if (!firebase.getApps().length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.getApp(); // if already initialized, use that one
  }
  const database = getDatabase(firebase.getApp());

  const publishedSpecialistsRef = ref(
    database,
    `published/specialists/${router.locale}`
  );
  const {
    data: specialists,
    isFetching: specialistsAreLoading,
    isError: specialistsError,
  } = useQuery([QueryKeys.SPECIALISTS, router.locale], () => getEntries(), {
    keepPreviousData: true,
    retry: 2,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  // wrap blog entries subscription into a promise to be used with react-query
  function getEntries(): Promise<Record<string, SpecialistData> | null> {
    return new Promise((resolve, reject) => {
      onValue(
        publishedSpecialistsRef,
        async (snapshot) => {
          const publishedEntries: Record<string, SpecialistData> =
            snapshot.val();
          resolve(publishedEntries);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  function move(from: number, to: number, arr: any[]) {
    const newArr = [...arr];

    const item = newArr.splice(from, 1)[0];
    newArr.splice(to, 0, item);

    return newArr;
  }

  return { specialists, specialistsAreLoading, specialistsError, move };
}
