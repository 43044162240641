import { ActionIcon, Divider, Text } from "@mantine/core";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Link from "next/link";
import { useColors } from "../../../hooks/colors/useColors";
import { mobile } from "../../../constants/env";
import { IconLanguage } from "@tabler/icons";
import {
  queryClient,
  QueryKeys,
} from "../../../hooks/react-query/query-client";

export const NavbarLanguagePicker = (props: {
  navbar?: boolean;
  textColor?: string;
}) => {
  const { colors } = useColors();
  const [showLangDialog, setShowLangDialog] = useState<boolean>(false);
  const { i18n } = useTranslation("common");
  const router = useRouter();
  const [showPicker, setShowPicker] = useState<boolean>(false);

  useEffect(() => {
    const route = router.route;
    if (route.includes("/blog/")) {
      setShowPicker(false);
    } else if (route.includes("/especialidades/")) {
      setShowPicker(false);
    } else if (route.includes("/especialistas/")) {
      setShowPicker(false);
    } else {
      setShowPicker(true);
    }
  }, [router.route]);

  async function changeLanguage(value: string) {
    await i18n.changeLanguage(value);
    setShowLangDialog(false);
    await queryClient.invalidateQueries(QueryKeys.SPECIALIST);
    await queryClient.invalidateQueries(QueryKeys.SPECIALTIES);
  }

  function returnLanguageName() {
    const currentLanguage = i18n.language;
    switch (currentLanguage) {
      case "pt":
        return "Português";
      case "es":
        return "Castellano";
      default:
        return "English";
    }
  }

  return (
    <Fragment>
      {showPicker && (
        <div
          className={`flex-col items-center justify-center`}
          onClick={() => setShowLangDialog(!showLangDialog)}
        >
          <div className="flex justify-center">
            <ActionIcon
              name="Language picker"
              onClick={() => setShowLangDialog(!showLangDialog)}
              color={colors.primary}
            >
              <IconLanguage />
            </ActionIcon>
          </div>
          {mobile && <Text>{returnLanguageName()}</Text>}
          {showLangDialog && (
            <div className="shadow-xl !bg-slate-50 dark:!bg-slate-800 !w-[200px] rounded-md !p-0 !overflow-hidden my-7 -ml-[90px] absolute">
              <Link href={router.pathname} locale="pt" className="no-underline">
                <div
                  onClick={() => changeLanguage("pt")}
                  className="hover:bg-slate-200 p-2 rounded-md dark:hover:bg-slate-900"
                >
                  Português (Portugal)
                </div>
              </Link>
              <Divider className="mx-2" />
              <Link href={router.pathname} locale="en" className="no-underline">
                <div
                  onClick={() => changeLanguage("en")}
                  className="hover:bg-slate-200 p-2 rounded-md dark:hover:bg-slate-900"
                >
                  English
                </div>
              </Link>
              <Divider className="mx-2" />
              <Link href={router.pathname} locale="es" className="no-underline">
                <div
                  onClick={() => changeLanguage("es")}
                  className="hover:bg-slate-200 p-2 rounded-md dark:hover:bg-slate-900"
                >
                  Castellano
                </div>
              </Link>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};
