import { Divider, Drawer, Text } from "@mantine/core";
import { Dispatch, Fragment, ReactNode } from "react";
import { CategoriesMenu } from "./popover-link/CategoriesMenu";
import Link from "next/link";
import { InfoMenu } from "./popover-link/InfoMenu";
import { ButtonLink } from "../../UI/button-link/ButtonLink";
import { IconUserCircle, IconVideo } from "@tabler/icons";
import { useColors } from "../../../hooks/colors/useColors";
import { useLinks } from "./popover-link/useLinks";
import { useTranslation } from "next-i18next";
import { DarkModeSwitch } from "../../UI/dark-mode-switch/DarkModeSwitch";
import { useUser } from "../../../hooks/react-query/useUser";
import dynamic from "next/dynamic";
import { LanguagePicker } from "../../UI/language-picker/LanguagePicker";

type InputProps = {
  opened: boolean;
  setOpened: Dispatch<boolean>;
  logo: ReactNode;
};

const UserMenu = dynamic(
  () => import("./UserMenu").then((mod) => mod.UserMenu),
  {
    ssr: false,
  }
);

export function MobileDrawer(props: InputProps) {
  const { opened, setOpened, logo } = props;
  const { specialtyLinks, specialistLinks } = useLinks();
  const { colors } = useColors();
  const { t } = useTranslation("common");
  const { user } = useUser();

  function closeMenuDrawer() {
    setOpened(false);
  }

  return (
    <Drawer
      zIndex={999}
      opened={opened}
      onClose={() => setOpened(false)}
      title={logo}
      padding="xs"
      size="xl"
    >
      <Divider />
      <div>
        <Fragment>
          <div className="my-2" onClick={closeMenuDrawer}>
            <ButtonLink
              href="/agendar"
              color={colors.primary}
              title={t("apps")}
            />
          </div>
          <Divider />
        </Fragment>
        {user && (
          <div className="my-2" onClick={closeMenuDrawer}>
            <Link href="/user">
              <div className="flex gap-2 items-center">
                <IconUserCircle color={colors.primary} size={30} />
                <Text className="!font-extrabold">{t("profile")}</Text>
              </div>
            </Link>
          </div>
        )}
        <Divider />
        <div className="my-2">
          <CategoriesMenu
            categoryMap={specialtyLinks()}
            closeMenuDrawer={closeMenuDrawer}
            href="/especialidades"
          />
        </div>
        <Divider />
        <div className="my-2">
          <CategoriesMenu
            doctors
            categoryMap={specialistLinks()}
            translate
            closeMenuDrawer={closeMenuDrawer}
            href="/especialistas"
          />
        </div>
        <Divider />
        <div className="my-2" onClick={closeMenuDrawer}>
          <Link href="/blog">
            <Text>{t("blog.name")}</Text>
          </Link>
        </div>
        <Divider />
        <div className="my-2">
          <InfoMenu closeMenuDrawer={closeMenuDrawer} />
        </div>
        <Divider />
        <div className="py-2">
          <LanguagePicker />
        </div>
        <Divider />
        <Fragment>
          <div className="my-2" onClick={closeMenuDrawer}>
            <ButtonLink
              href="/"
              icon={<IconVideo color={colors.white} />}
              color={colors.secondary}
              title={t("video-calls")}
            />
          </div>
          <Divider />
        </Fragment>
        <div className="flex gap-4 m-2 justify-center items-center">
          {/*<IconMail size={mobile ? 50 : undefined} color={colors.tertiary} />*/}
          {/*<IconBell size={mobile ? 50 : undefined} color={colors.secondary} />*/}
          <UserMenu />
          <DarkModeSwitch />
        </div>
        {/*)}*/}
      </div>
    </Drawer>
  );
}
