import { useColors } from "../../../hooks/colors/useColors";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import useAsyncEffect from "use-async-effect";
import { useRouter } from "next/router";
import { Fragment, useEffect } from "react";
import en from "./en.json";
import pt from "./pt.json";
import es from "./es.json";

export function CookieAccept() {
  const { isDarkMode } = useColors();
  const router = useRouter();
  useAsyncEffect(async () => {
    if (router.locale) {
      await CookieConsent.run({
        onFirstConsent: ({ cookie }) => {
          if (cookie.categories.includes("analytics")) window.location.reload();
        },
        cookie: { name: "cc_cookie" },
        categories: {
          necessary: {
            enabled: true,
            readOnly: true,
          },
          analytics: {},
        },
        language: {
          default: router.locale,
          // autoDetect: "document",
          translations: {
            en: en,
            pt: pt,
            // @ts-ignore
            es: es,
          },
        },
      });
    }
  }, [router.locale]);

  // change cookie banner theme
  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("cc--darkmode");
    } else {
      document.documentElement.classList.remove("cc--darkmode");
    }
  }, [isDarkMode]);
  return <Fragment />;
}
