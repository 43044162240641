import { create } from "zustand";

interface DarkModeState {
  darkMode: boolean;
  toggle: () => void;
  setDarkMode: (value: boolean) => void;
}

export const useDarkModeStore = create<DarkModeState>()((set) => ({
  darkMode: false,
  toggle: () => set((state) => ({ darkMode: !state.darkMode })),
  setDarkMode: (value: boolean) => set((state) => ({ darkMode: value })),
}));
