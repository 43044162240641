import { LinkContainer } from "./LinkContainer";
import { useLinks } from "./useLinks";
import { useTranslation } from "next-i18next";

export function InfoMenu(props: { closeMenuDrawer?: () => void }) {
  const { infoLinks } = useLinks();
  const { t } = useTranslation("common");

  return (
    <div>
      <LinkContainer
        minWidth="14rem"
        // teamTitle
        title={t("information")}
        linkMap={infoLinks()}
        closeMenuDrawer={props.closeMenuDrawer}
      />
    </div>
  );
}
