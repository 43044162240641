import Link from "next/link";
import { Text } from "@mantine/core";
import { useColors } from "../../../hooks/colors/useColors";
import { ReactNode } from "react";
import classes from "./button-link.module.css";

type InputProps = {
  href: string;
  color: string;
  title: ReactNode;
  icon?: ReactNode;
  rounded?: boolean;
  textColor?: string;
  uppercase?: boolean;
  className?: string;
};

export function ButtonLink(props: InputProps) {
  const { href, color, title, icon, rounded, textColor, uppercase, className } =
    props;
  const { colors } = useColors();
  let linkClass = `${classes.link} px-4 py-2 rounded-lg flex gap-2 justify-center items-center`;
  if (rounded)
    linkClass = `${classes.link} px-4 py-2 rounded-full flex gap-2 justify-center items-center`;
  linkClass = `${className} ${linkClass}`;
  return (
    <Link href={href}>
      <div className={linkClass} style={{ backgroundColor: color }}>
        {icon && icon}
        <div
          className={uppercase ? "uppercase font-bold" : "font-bold"}
          style={{ color: textColor || colors.white }}
        >
          <div className="font-bold" style={{ color: textColor }}>
            {title}
          </div>
        </div>
      </div>
    </Link>
  );
}
