import { MyButton } from "../../../UI/my-button/MyButton";
import { useColors } from "../../../../hooks/colors/useColors";
import { Container } from "../../../UI/container/Container";
import { IconBrandWhatsapp, IconPhone, IconX } from "@tabler/icons";
import { ActionIcon, Text, Card, Checkbox, TextInput } from "@mantine/core";
import { Fragment, useState } from "react";
import { mobile } from "../../../../constants/env";
import { useUser } from "../../../../hooks/react-query/useUser";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { useAssessment } from "../assessment/useAssessment";
import Link from "next/link";
import dynamic from "next/dynamic";
import { MyInput } from "../../../UI/my-input/MyInput";

const MyPhone = dynamic(
  () => import("./../../../UI/phone/MyPhone").then((mod) => mod.MyPhone),
  {
    ssr: false,
  }
);
export function ContactButtons() {
  const { colors } = useColors();
  const [openPop, setOpenPop] = useState<boolean>(false);
  const { t } = useTranslation("common");
  const { sendPhone, processing, error, setError } = useAssessment();
  const { user } = useUser();

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      phone: "",
      acceptTerms: false,
    },
  });

  function closeForm() {
    setError("");
    reset();
    setOpenPop(false);
  }

  return (
    <Fragment>
      {!user && (
        <div className="fixed bottom-0 right-0 z-10">
          {!user && (
            <Container noHeight>
              <div className="flex justify-end gap-6 items-center">
                <div>
                  {openPop && (
                    <form
                      style={{ backgroundColor: colors.secondaryBackground }}
                      onSubmit={handleSubmit(async (data) => {
                        await sendPhone(data, closeForm);
                      })}
                    >
                      <Card
                        radius="xl"
                        shadow="xl"
                        withBorder
                        className="w-full mb-2 rounded-xl p-2"
                        style={{ backgroundColor: colors.secondaryBackground }}
                      >
                        <div className="flex justify-end w-full mb-2">
                          <ActionIcon
                            color={colors.primary}
                            onClick={closeForm}
                          >
                            <IconX color="white" />
                          </ActionIcon>
                        </div>
                        <Text className="text-center w-[250px] mt-4 py-2">
                          {t("contactMeDetails")}
                        </Text>
                        <TextInput
                          className="mb-4"
                          error={errors.name?.message}
                          withAsterisk
                          type="text"
                          label={t("name")}
                          // @ts-ignore
                          placeholder={t("name")}
                          styles={{
                            input: {
                              height: 50,
                            },
                          }}
                          {...register("name", {
                            // @ts-ignore
                            required: t("login.nameError"),
                            minLength: {
                              value: 3,
                              message: t("login.nameError"),
                            },
                            maxLength: {
                              value: 99,
                              message: t("login.nameError"),
                            },
                          })}
                        />
                        <MyPhone
                          hideIcon
                          // @ts-ignore
                          label={t("phone")}
                          field="phone"
                          stringValue
                          setFormValue={setValue}
                          value={watch().phone}
                          required
                        />
                        <Checkbox
                          className="mt-2 w-[250px]"
                          error={errors.acceptTerms?.message}
                          label={
                            <div className="flex flex-col">
                              {t("agreeWith")}{" "}
                              <Link href="/termos-e-condicoes">
                                <div
                                  className="mb-2 font-semibold"
                                  style={{ color: colors.primary }}
                                >
                                  {t("privacy")}
                                </div>
                              </Link>
                            </div>
                          }
                          {...register("acceptTerms", {
                            // @ts-ignore
                            required: t("login.acceptTermsError"),
                          })}
                        ></Checkbox>
                        <Text className="!text-red-500 w-[250px] text-center">
                          {error}
                        </Text>
                        <MyButton
                          submit
                          disabled={processing}
                          loading={processing}
                          className="mt-2 w-full"
                          onClick={() => {}}
                          title={t("send")}
                          color={colors.primary}
                        />
                      </Card>
                    </form>
                  )}
                  <MyButton
                    className="w-[270px]"
                    icon={<IconPhone color="white" />}
                    onClick={() => setOpenPop(!openPop)}
                    title={t("contactMe")}
                    color={colors.secondary}
                    textColor="white"
                  />
                </div>

                {!openPop && mobile && (
                  <ActionIcon
                    title="whatsapp"
                    aria-label="whatsapp"
                    color={colors.success}
                    size={48}
                    variant="light"
                    radius={30}
                  >
                    <IconBrandWhatsapp color={colors.success} size={30} />
                  </ActionIcon>
                )}
              </div>
            </Container>
          )}
        </div>
      )}
    </Fragment>
  );
}
