import { ActionIcon, Menu, Text } from "@mantine/core";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Link from "next/link";
import { useColors } from "../../../hooks/colors/useColors";
import { mobile } from "../../../constants/env";
import { IconLanguage } from "@tabler/icons";
import {
  queryClient,
  QueryKeys,
} from "../../../hooks/react-query/query-client";

export const LanguagePicker = (props: {
  navbar?: boolean;
  textColor?: string;
}) => {
  const { colors } = useColors();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { i18n } = useTranslation("common");
  const router = useRouter();
  const [showPicker, setShowPicker] = useState<boolean>(false);

  useEffect(() => {
    const route = router.route;
    if (route.includes("/blog/")) {
      setShowPicker(false);
    } else if (route.includes("/especialidades/")) {
      setShowPicker(false);
    } else if (route.includes("/especialistas/")) {
      setShowPicker(false);
    } else {
      setShowPicker(true);
    }
  }, [router.route]);

  async function changeLanguage(value: string) {
    await i18n.changeLanguage(value);
    setShowMenu(false);
    await queryClient.invalidateQueries(QueryKeys.SPECIALIST);
    await queryClient.invalidateQueries(QueryKeys.SPECIALTIES);
  }

  function returnLanguageName() {
    const currentLanguage = i18n.language;
    switch (currentLanguage) {
      case "pt":
        return "Português";
      case "es":
        return "Castellano";
      default:
        return "English";
    }
  }

  const noDecoration = {
    textDecoration: "none",
  };

  return (
    <Fragment>
      {showPicker && (
        <div className="relative hover:cursor-pointer">
          <Menu
            shadow="md"
            width={200}
            opened={showMenu}
            position="bottom"
            zIndex={999}
          >
            <Menu.Target>
              <div
                className="flex gap-5 items-center"
                onClick={() => setShowMenu(!showMenu)}
              >
                <ActionIcon
                  name="Language picker"
                  onClick={() => setShowMenu(!showMenu)}
                  color={colors.primary}
                >
                  <IconLanguage />
                </ActionIcon>
                {mobile && <Text>{returnLanguageName()}</Text>}
              </div>
            </Menu.Target>
            <Menu.Dropdown className="!top-12 z-10">
              <Link href={router.pathname} locale="pt" style={noDecoration}>
                <Menu.Item onClick={() => changeLanguage("pt")}>
                  Português (Portugal)
                </Menu.Item>
              </Link>
              <Link href={router.pathname} locale="en" style={noDecoration}>
                <Menu.Item onClick={() => changeLanguage("en")}>
                  English
                </Menu.Item>
              </Link>
              <Link href={router.pathname} locale="es" style={noDecoration}>
                <Menu.Item onClick={() => changeLanguage("es")}>
                  Castellano
                </Menu.Item>
              </Link>
            </Menu.Dropdown>
          </Menu>
        </div>
      )}
    </Fragment>
  );
};
