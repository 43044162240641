import { BACKEND_URL } from "../../../../constants/env";
import { useState } from "react";
import { useTranslation } from "next-i18next";
import { useToastStore } from "../../../config/store/toastStore";
import { UseFormReset } from "react-hook-form/dist/types/form";

export function useAssessment() {
  const [processing, setProcessing] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { i18n } = useTranslation("common");
  const { setToast } = useToastStore();

  async function sendAssessment(form: object, resetForm: UseFormReset<any>) {
    setProcessing(true);
    setError("");
    try {
      const serverResponse = await sendContactForm("assessment", form);

      switch (serverResponse.status) {
        case 200:
          setToast(i18n.t("success"), i18n.t("contact.success"), 20000);
          resetForm();
          break;
        case 422:
          // @ts-ignore
          setError(i18n.t("formErrors"));
          break;
        default:
          // @ts-ignore
          setError(i18n.t("contact.error"));
      }
      setProcessing(false);
    } catch (err) {
      console.error(err);
      // @ts-ignore
      setError(i18n.t("contact.error"));
      setProcessing(false);
    }
  }

  async function sendPhone(form: object, closeForm: () => void) {
    setProcessing(true);
    setError("");
    try {
      const serverResponse = await sendContactForm("contact", form);

      switch (serverResponse.status) {
        case 200:
          setToast(i18n.t("success"), i18n.t("contact.success"), 20000);
          closeForm();
          break;
        case 422:
          // @ts-ignore
          setError(i18n.t("formErrors"));
          break;
        default:
          // @ts-ignore
          setError(i18n.t("contact.error"));
      }
      setProcessing(false);
    } catch (err) {
      console.error(err);
      // @ts-ignore
      setError(i18n.t("contact.error"));
      setProcessing(false);
    }
  }
  async function sendContactForm(url: string, form: object) {
    return await fetch(`${BACKEND_URL}/mail/${url}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(form),
    });
  }

  return { processing, sendAssessment, error, sendPhone, setError };
}
