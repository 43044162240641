import { FloatingPosition, Menu, Text } from "@mantine/core";
import { Fragment, ReactNode } from "react";
import Link from "next/link";
import { useColors } from "../../../../hooks/colors/useColors";
import { IconChevronDown, IconChevronRight } from "@tabler/icons";
import { useRouter } from "next/router";
import { mobile } from "../../../../constants/env";

type InputProps = {
  title: ReactNode;
  linkMap?: Record<string, string>;
  dropDown?: ReactNode;
  teamTitle?: boolean;
  width?: number;
  position?: FloatingPosition;
  target?: ReactNode;
  link?: string;
  closeMenuDrawer?: () => void;
  minWidth?: string;
  href?: string;
};

export function LinkContainer({
  title,
  linkMap,
  dropDown,
  teamTitle,
  width,
  position,
  link,
  closeMenuDrawer,
  minWidth,
  href,
}: InputProps) {
  const { colors } = useColors();
  const router = useRouter();

  const Dropdown = () => (
    <Fragment>
      {linkMap && (
        <Fragment>
          {Object.keys(linkMap).map((key, index) => {
            return (
              <Link href={linkMap[key]} key={key}>
                <Menu.Item
                  key={index}
                  onClick={closeMenuDrawer}
                  classNames={{}}
                >
                  <Text className="dark:text-slate-100">{key}</Text>
                </Menu.Item>
              </Link>
            );
          })}
        </Fragment>
      )}
    </Fragment>
  );

  async function goToLink() {
    if (link) {
      await router.push(link);
    }
  }

  return (
    <Menu
      zIndex={10}
      // opened
      shadow="md"
      trigger="hover"
      openDelay={100}
      closeDelay={400}
      width={width}
      position={position}
      styles={{
        dropdown: {
          maxWidth: mobile ? "92vw" : "20rem",
          minWidth: minWidth || "20rem",
          backgroundColor: colors.secondary,
          maxHeight: "60vh",
          overflowY: "auto",
          opacity: 1,
          zIndex: 999,
        },
      }}
    >
      <Menu.Target>
        <div>
          {!teamTitle && (
            <Fragment>
              {!href && (
                <div className="flex items-center hover">
                  <Text
                    className="!font-bold"
                    c={teamTitle ? colors.white : undefined}
                  >
                    {title}
                  </Text>
                  <IconChevronDown size={16} />
                </div>
              )}
              {href && (
                <Link href={href} className="flex items-center hover">
                  <Text
                    className="!font-bold"
                    c={teamTitle ? colors.white : undefined}
                  >
                    {title}
                  </Text>
                  <IconChevronDown size={16} />
                </Link>
              )}
            </Fragment>
          )}
          {teamTitle && (
            <Menu.Item onClick={goToLink}>
              <div className="flex items-center justify-between">
                <Text>{title}</Text>
                <IconChevronRight size={16} color={colors.white} />
              </div>
            </Menu.Item>
          )}
        </div>
      </Menu.Target>

      <Menu.Dropdown color={colors.primary} className="rounded-xl z-10">
        {!dropDown && <Dropdown />}
        {dropDown && dropDown}
      </Menu.Dropdown>
    </Menu>
  );
}
