import { useQuery } from "react-query";
import { QueryKeys } from "./query-client";
import { useFirebaseAuth } from "../firebase/useFirebaseAuth";

export function useUser() {
  const { getUser } = useFirebaseAuth();

  const { data: user, isLoading: userIsLoading } = useQuery(
    [QueryKeys.USER],
    () => getUser(),
    {
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchInterval: 300000, // 5 min
    }
  );

  return { user, userIsLoading };
}
