import { QueryClient } from "react-query";

export const QueryKeys = {
  APPOINTMENTS: "appointments",
  PAST_APPOINTMENTS: "past-appointments",
  USER: "user",
  STATS: "stats",
  MESSAGES: "messages",
  MESSAGE_THREAD: "message-thread",
  PAYMENTS: "payments",
  BLOGS: "blogs",
  INVOICES: "invoices",
  SPECIALISTS: "specialists",
  PARTNERSHIPS: "partnerships",
  SCHEDULES: "schedules",
  PSYCHOLOGISTS: "psychologists",
  DOCTORS: "doctors",
  SPECIALIST: "specialist",
  SPECIALTIES: "specialties",
  SPECIALTY: "specialty",
  MULTI: "multi",
  MULTI_LIST: "multi-list",
  JWT: "jwt",
  VIDEO_MEETINGS: "video-meetings",
  ATTACHMENTS: "attachments",
  NOTIFICATIONS: "notifications",
  VIDEO_MEETING: "video-meeting",
};

export const defaultQueryClientOptions = {
  queries: {
    // onError: queryErrorHandler,
    staleTime: 600000, // 10 minutes
    cacheTime: 900000, // default cacheTime is 5 minutes; doesn't make sense for staleTime to exceed cacheTime
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  },
};

export const queryClient = new QueryClient({
  defaultOptions: defaultQueryClientOptions,
});
