import { create } from "zustand";

interface ToastState {
  toast: {
    show: boolean;
    title: string;
    description: string;
    duration: number;
  };
  showToast: () => void;
  hideToast: () => void;
  setToast: (title: string, description: string, duration?: number) => void;
}

export const useToastStore = create<ToastState>()((set) => ({
  toast: {
    show: false,
    title: "",
    description: "",
    duration: 3000,
  },
  showToast: () => set((state) => ({ toast: { ...state.toast, show: true } })),
  hideToast: () => set((state) => ({ toast: { ...state.toast, show: false } })),
  setToast: (title: string, description: string, duration?: number) =>
    set((state) => ({
      toast: {
        show: true,
        description: description,
        title: title,
        duration: duration || 3000,
      },
    })),
}));
