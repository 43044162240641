import { Button, ButtonVariant, MantineSize } from "@mantine/core";
import { CSSProperties, ReactNode } from "react";

class MantineNumberSize {}

type InputProps = {
  onClick(): void;
  title: ReactNode;
  color: string;
  textColor?: string;
  loading?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  icon?: ReactNode;
  noMargin?: boolean;
  className?: string;
  marginTop?: boolean;
  marginBottom?: boolean;
  submit?: boolean;
  size?: MantineSize;
  rounded?: boolean;
  variant?: ButtonVariant;
  uppercase?: boolean;
  height?: number | string;
  fontSize?: MantineNumberSize | string;
  rightIcon?: ReactNode;
  fullWidth?: boolean;
};

export function MyButton(props: InputProps) {
  const {
    textColor,
    icon,
    color,
    height,
    loading,
    onClick,
    disabled,
    className,
    title,
    marginTop,
    marginBottom,
    submit,
    size,
    rounded,
    variant,
    uppercase,
    fontSize,
    rightIcon,
    fullWidth,
  } = props;

  let combinedStyles = {
    backgroundColor: variant ? undefined : color,
    fontSize: fontSize || 16,
    color: textColor,
    width: fullWidth ? "100%" : undefined,
    height: height || 40,
    borderRadius: rounded ? 999 : 10,
    marginTop: marginTop ? 16 : undefined,
    marginBottom: marginBottom ? 16 : undefined,
  };

  return (
    <Button
      // title={title}
      aria-label={title}
      onClick={onClick}
      className={`${className} hover:opacity-80`}
      size={size ? size : undefined}
      disabled={disabled}
      loading={loading}
      leftSection={icon}
      variant={variant}
      // uppercase={uppercase}
      type={submit ? "submit" : "button"}
      styles={{
        // @ts-ignore
        root: {
          ...combinedStyles,
          backgroundColor: variant ? undefined : color,
          color: variant ? color : undefined,
          // "&:hover": {
          //   backgroundColor: variant ? undefined : color,
          //   opacity: 0.9,
          // },
        },
        label: {
          color: textColor,
        },
      }}
    >
      {title}
      {rightIcon && rightIcon}
    </Button>
  );
}
