import { ref, onValue, getDatabase } from "firebase/database";
// import { database } from "../../services/firebase/initFirebase";
import { QueryKeys } from "./query-client";
import { useQuery } from "react-query";
import { EntryData } from "../../constants/model/models";
import * as firebase from "firebase/app";
import { firebaseConfig } from "../../constants/env";

export interface MultiData extends EntryData {
  name: string;
}

export function useMultiList() {
  if (!firebase.getApps().length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.getApp(); // if already initialized, use that one
  }
  const database = getDatabase(firebase.getApp());

  const publishedSpecialtiesRef = ref(database, "published/multi");
  const {
    data: multi,
    isFetching: multiAreLoading,
    isError: multiError,
  } = useQuery([QueryKeys.MULTI_LIST], () => getEntries(), {
    keepPreviousData: true,
    retry: 2,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  // wrap blog entries subscription into a promise to be used with react-query
  function getEntries(): Promise<Record<string, MultiData> | null> {
    return new Promise((resolve, reject) => {
      onValue(
        publishedSpecialtiesRef,
        async (snapshot) => {
          const publishedEntries: Record<string, MultiData> = snapshot.val();
          resolve(publishedEntries);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  return { multi, multiAreLoading, multiError };
}
