import { useUser } from "../../../hooks/react-query/useUser";
import { Fragment, ReactNode, useState } from "react";
import { MyButton } from "../../UI/my-button/MyButton";
import { Modal, Text } from "@mantine/core";
import { useTranslation } from "next-i18next";
import { useOpenLoginMenuStore } from "../../config/store/useOpenLoginMenuStore";
import { IconHandStop } from "@tabler/icons";
type InputProps = {
  title: string;
  color: string;
  warning: string;
  forbiddenIcon: ReactNode;
  buttonIcon?: ReactNode;
};
export function AnonymousButton(props: InputProps) {
  const { title, color, warning, buttonIcon, forbiddenIcon } = props;
  const { user } = useUser();
  const [openAnonymousModal, setOpenAnonymousModal] = useState<boolean>(false);
  const { i18n } = useTranslation("common");
  const { setOpenLoginMenu } = useOpenLoginMenuStore((state) => state);

  function renderOpenMenuLink(title: string) {
    return (
      <a
        href="#"
        className="text-blue-600"
        onClick={() => setOpenLoginMenu(true)}
      >
        {title}
      </a>
    );
  }

  return !user ? (
    <Fragment>
      <MyButton
        icon={buttonIcon}
        onClick={() => setOpenAnonymousModal(true)}
        title={title}
        color={color}
        textColor="white"
      />
      <Modal
        opened={openAnonymousModal}
        onClose={() => {
          setOpenAnonymousModal(false);
        }}
        centered
      >
        <div className="flex flex-col items-center justify-center">
          {forbiddenIcon}
          <Text className="text-xl text-center my-6 font-bold">
            <span>{warning}</span>
            {renderOpenMenuLink(i18n.t("booking.anonymous-login"))}
            <span>{` ${i18n.t("booking.or")} `}</span>
            {renderOpenMenuLink(i18n.t("booking.anonymous-signup"))}
          </Text>
        </div>
      </Modal>
    </Fragment>
  ) : null;
}
